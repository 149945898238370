import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import VueCompositionAPI, { watch } from '@vue/composition-api';
import ro from 'vee-validate/dist/locale/ro.json';
import PageBar from '@/components/PageBar.vue';
const loading = () => store.dispatch('auth/initialize')
    .then(() => {
    watch(() => store.state.auth.token, (current, prev) => {
        if (prev === undefined) {
            return;
        }
        if (!current && !prev) {
            router.push({ name: 'home' });
        }
        else if (!current) {
            window.location.href = '/home';
        }
    }, { immediate: true });
});
router.beforeEach(async (to, from, next) => {
    const tokenlessRoutes = ['activate'];
    if (!tokenlessRoutes.includes(to.name || '')) {
        await loading();
    }
    next();
});
Vue.config.productionTip = false;
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
localize('ro', ro);
Vue.use(VueCompositionAPI);
Vue.component('PageBar', PageBar);
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
