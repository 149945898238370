import axios from '@/plugins/axios';
import { extractUserJwtData, getExpiryTimeout } from '@/lib/auth';
const expiryTimeout = getExpiryTimeout({
    preceding: 5 // 5 seconds
});
let authorizationTimer;
const state = {
    error: null,
    errors: [],
    user: null,
    initializing: true,
    submitting: false,
    refreshing: false,
    token: null
};
const getters = {
    isGuest: state => !state.token
};
const mutations = {
    authorize: (state, { authorizationToken }) => {
        state.user = extractUserJwtData(authorizationToken);
        state.token = authorizationToken;
    },
    beginInitialization: (state) => {
        state.initializing = true;
    },
    submit: state => {
        state.error = null;
        state.errors = [];
        state.submitting = true;
    },
    refresh: state => {
        state.error = null;
        state.refreshing = true;
    },
    setError: (state, error) => {
        state.error = error;
    },
    setErrors: (state, errors) => {
        state.errors = errors;
    },
    finish: state => {
        state.initializing = false;
        state.submitting = false;
        state.refreshing = false;
    },
    logout: state => {
        state.token = null;
        state.user = null;
    }
};
const actions = {
    login: ({ commit, dispatch }, { email, password }) => Promise
        .resolve(commit('submit'))
        .then(() => axios.post('/auth/login', { email, password }, { withCredentials: true }))
        .then((res) => {
        return dispatch('authorize', res.data.data);
    })
        .finally(() => commit('finish')),
    initialize: ({ commit, dispatch }) => {
        commit('beginInitialization');
        return dispatch('refresh', { silent: true });
    },
    refresh: ({ commit, dispatch }, { silent = false } = {}) => Promise
        .resolve(commit('refresh'))
        .then(() => axios.post('/auth/refresh', undefined, { withCredentials: true }))
        .then(response => {
        return dispatch('authorize', response.data.data);
    })
        .catch((e) => {
        if (e.response) {
            if (silent && e.response.status === 401) {
                return;
            }
            commit('setError', new Error(e.response.data.error));
        }
        else {
            commit('setError', e);
        }
        commit('logout');
    })
        .finally(() => commit('finish')),
    authorize: async ({ commit, dispatch, state }, payload) => {
        commit('authorize', payload);
        if (authorizationTimer) {
            clearTimeout(authorizationTimer);
        }
        if (!state.token || !state.user) {
            return;
        }
        const timeout = expiryTimeout(new Date(), state.user.exp);
        authorizationTimer = setTimeout(() => dispatch('refresh'), timeout < 0 ? 0 : timeout);
    },
    logout: ({ commit }) => axios.post('/auth/logout', undefined, { withCredentials: true })
        .then(() => {
        commit('logout');
    })
        .catch(e => {
        if (e.response) {
            if (e.response.status === 401) {
                commit('logout');
            }
            else {
                return Promise.reject(new Error(e.response.data.message));
            }
        }
        else {
            commit('setError', e);
        }
    })
};
export const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
